import React from 'react';
import Modal from 'react-modal';
const customStyles = {

    content: {
        display: 'flex',
        flexDirection: 'column',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
    }
};
const ModalWorkingHours = ({ isOpen, onClose, workingHours, days }) => {

    return (
        <Modal style={customStyles} onRequestClose={onClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} isOpen={isOpen} >
            <h2>Horários de funcionamento:</h2>
            <table className='workingTable'>

                <button onClick={onClose} className='closeButton'>X</button>

                <tbody>
                    {Object.entries(workingHours).map(([day, hours]) => {
                        return (
                            <tr key={day}>
                                <td className='weekday'>{days[day]}</td>
                                <td>
                                    <p>
                                        {hours.length === 0 ? 'Fechado' : ''}
                                        {hours.map(([start, end]) => {
                                            return (

                                                `${start} - ${end}`

                                            );
                                        }).join(', ')}
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Modal>
    );
}
export default ModalWorkingHours;