import './App.css';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import ModalWorkingHours from './modalWorkHours/modalWorkHours';
ReactGA.initialize('G-TCZDJE82ES');

const goToWhatsapp = () => {
  ReactGA.event("whatsapp-clicked")
  window.open('https://api.whatsapp.com/send/?phone=5511970196824&text&type=phone_number&app_absent=0', '_blank');
}
const goToOwnMenu = () => {
  ReactGA.event("own-menu-clicked")
  window.open('https://roleplayburger.menudino.com/', '_blank');
}
const goToIfood = () => {
  ReactGA.event("ifood-clicked")
  window.open('https://www.ifood.com.br/delivery/sao-paulo-sp/roleplay-----hamburguer-artesanal-conjunto-residencial-butanta/00d5f1f5-9c39-4be4-a31f-e65502c1b275', '_blank');
}

const workingHours = {
  0: [['11:30', '14:00'], ['18:30', '23:59']],
  1: [['11:30', '14:00'], ['18:30', '23:59']],
  2: [],
  3: [],
  4: [['11:30', '14:00'], ['18:30', '23:59']],
  5: [['11:30', '14:00'], ['18:30', '23:59']],
  6: [['11:30', '14:00'], ['18:30', '23:59']],

}
const days = {
  0: "Domingo",
  1: "Segunda",
  2: "Terça",
  3: "Quarta",
  4: "Quinta",
  5: "Sexta",
  6: "Sábado",

}
const isOpened = () => {
  const date = new Date();
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const now = `${hours}:${minutes}`;
  const open = workingHours[day].some(([start, end]) => {
    return start < now && now < end;
  });

  return open;
}
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

const nextOpen = (mockData) => {
  let date = new Date();
  if (mockData) {
    date = new Date(mockData);
  }
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const now = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

  for (let i = 0; i <= 6; i++) {

    let checkDay = day
    if (checkDay + i > 6) {
      checkDay = 0;
    } else {
      checkDay = checkDay + i;
    }
    for (const [start,] of workingHours[checkDay]) {
      if (i === 0 && start < now) {
        continue; // Se for hoje mas antes do horário de abertura, continue procurando
      }
      // Encontrou o próximo horário de abertura
      const nextDate = addDays(date, i);
      const [nextHours, nextMinutes] = start.split(":");
      nextDate.setHours(nextHours, nextMinutes, 0, 0); // Configura para o próximo horário de abertura
      let week = days[checkDay];
      if (day === checkDay) {
        week = "Hoje";


        let leftMinutes = Number(nextMinutes - minutes) + (Number(nextHours - hours) * 60);

        if (leftMinutes < 60) {

          return ` em ${leftMinutes} minutos`;
        }


      } else if (day + i === checkDay) {
        week = "Amanhã";

      }


      return `${week} as ${nextHours}:${nextMinutes}`;

    }

  }
};
function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [nextOpenText, setNextOpenText] = useState("");
  const [location, setLocation] = useState(null);
  const [distance, setDistance] = useState(0);


  const inDeliveryArea = useMemo(() => {
    if (distance === null) {
      return null;
    }
    if (distance <= 5) {
      return true;
    } else if (distance <= 7) {
      return true;
    } else {
      return false;
    }
  }, [distance])
  const locationText = useMemo(() => {
    if (distance === null || distance === 0) {
      return null;
    }
    if (distance <= 5) {
      ReactGA.event("user-in-delivery-area", { distance: distance })
      return "Atendemos sua região";
    } else if (distance <= 7) {
      ReactGA.event("user-in-ifood-delivery-area", { distance: distance })
      return "Atendemos sua região, somente pelo Ifood";
    } else {
      ReactGA.event("user-not-in-area", { distance: distance })
      return "Infelizmente não atendemos sua região";
    }

  }, [distance])

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      ReactGA.event("location-clicked")
      ReactGA.set({ location: `${position.coords.latitude},${position.coords.longitude}` });
      setLocation(position);
    }
    )
  }
  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lon2 - lon1);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);

    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
  useEffect(() => {
    const cords = { lat: -23.576, lon: -46.726 };
    if (location) {
      const distance = getDistanceFromLatLonInKm(location.coords.latitude, location.coords.longitude, cords.lat, cords.lon);
      setDistance(distance)
      ReactGA.event("latlon", { "latlon": `${location.coords.latitude},${location.coords.longitude}`, distance: distance })
    } else {

      setDistance(null)
    }
  }, [location])
  useEffect(() => {
    //check if location permission is granted without show the popup
    try {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          getLocation();
        }
      });
    } catch {

    }
    setNextOpenText(nextOpen());
    const i = setInterval(() => {
      setNextOpenText(nextOpen());
    }, 15000);
    return () => clearInterval(i);
  }, []);
  return (
    <div className="App">
      <ModalWorkingHours workingHours={workingHours} days={days} isOpen={isOpen} onClose={() => { setIsOpen(false) }} />
      <img src={"/back.png"} className="background-image" alt="logo" />
      <img src={"/logo.png"} className="App-logo" alt="logo" />
      <p className='motd'>Peça já por um dos nossos canais</p>
      <div onClick={() => { setIsOpen(true); ReactGA.event("look-working-hours") }} className='hour-container'>
        <div className={'hour-status ' + (isOpened() ? "open" : "close")}>
          {isOpened() ? "Estamos Abertos" : "Fechado, abrimos " + nextOpenText}

        </div>
        <p className='hour-text'>Ver Horários</p>
      </div>
      {
        locationText !== null ? <p className={'location-final ' + (inDeliveryArea ? 'true' : 'false')}>{locationText}</p> : <p onClick={getLocation} className='location-text'>Clique aqui para saber se atendemos sua região</p>

      }

      <div onClick={goToOwnMenu} className='own-menu'>
        <img src={"/logoYellow.png"} className="logo" alt="menu" />
        <div className="own-menu-text">
          <div className='internal'>
            <h3 className='menu-text'>Cardápio Próprio</h3>
            <p className='menu-text'>Opções até 30% mais baratas do que pelo IFood</p>
          </div>
        </div>
      </div>


      <div onClick={goToIfood} className='own-menu'>
        <img src={"/icone-ifood.png"} className="logo" alt="menu" />
        <div className="own-menu-text">
          <div className='internal'>
            <h3 className='menu-text'>IFood - Cardápio</h3>
          </div>

        </div>
      </div>
      <p className='contact-text'>
        Ou entre em contato conosco<br /> pelo WhatsApp
      </p>

      <img onClick={goToWhatsapp} src={"/whatsapp.png"} className="whatsapp" alt="whatsapp" />
    </div>
  );
}

export default App;
